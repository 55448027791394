import { valueMapping } from './constants';
import { assertUnionType } from 'graphql';
import { isJobTrackEnable } from '/lib/helpers';

import nookies from 'nookies';
import qs from 'qs';
import { getFilterLanguage } from '/imports/job-tracking/api/helper.js';
import env from '/env';

//boards api start here
export const getBoards = async (regionCode, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board?regionCode=${regionCode}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const changeColumn = async (obj, boardId, columnId, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(obj),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}/${columnId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateBoard = async (boardName, boardId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(boardName),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const createNewBoard = async (boardName, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(boardName),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteBoard = async (id, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

//jobs api start here
export const addJobTrackingData = async (fields, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  [('createdAt', '_id', 'tag')].forEach((e) => delete fields[e]);
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteJobTrackingData = async (id, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${id}?type=jobId`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateJobTrackPosition = async (token, jobId, boardId, position, controller = {}) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ position }),
    ...controller,
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateJobTrackingData = async (updatedFields, jobId, boardId, token) => {
  const { agent_client_id } = nookies.get({});
  const fields = { ...updatedFields };
  [
    'updatedAt',
    'createdAt',
    'userId',
    'jobId',
    'notes',
    'boardId',
    'comments',
    'tasks',
    'timelines',
    'contacts',
    'zipCode',
    'contractCategories',
    'createdBy',
    'hasPendingTask',
  ].forEach((e) => delete fields[e]);
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(fields),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/${boardId}/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const getJobAdditionalInfo = async (jobId, boardId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/${jobId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// search and feeds elastic search
export const esQuery = async (query, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(query),
  };
  const response = await fetch(`${env.BASE_API}/es/query`, fetchOptions);

  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getSearchJob = async (data, token, index, podioUserId) => {
  const remoteOnly = data?.remote ? data.remote !== 'On Site' : false;
  const { agent_client_id } = nookies.get({});
  let postData = {
    title: data?.title,
    location: data?.where,
    datePosted: data?.postedDate,
    remoteOnly: remoteOnly,
    index: index || 0,
    employmentTypes: data?.jobType?.length ? data?.jobType[0] : '',
    language: data?.language ? getFilterLanguage(data?.language) : '',
  };
  if (data?.allowedJobProviders?.length > 0) {
    postData.allowedJobProviders = data.allowedJobProviders;
  }
  const isGuest = !token && isJobTrackEnable();
  const guestId = localStorage.getItem('guestId');
  const API = isGuest ? `/job/guest-search?guestId=${guestId}` : '/job/search-v2';
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(postData),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}${API}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const listAgentUser = async (queryData) => {
  const queryParams = qs.stringify(queryData);
  let url = `${env.JOB_TRACK_API}/agent-user/list?${queryParams}`;
  const res = await fetch(url);
  return res.json();
};

export const getSearchData = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/filters`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// task api start here
export const getTasks = async (token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/task/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getTaskByCursor = async (taskId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (taskId === 0) URL = `${env.JOB_TRACK_API}/task/?limit=5`;
  else URL = `${env.JOB_TRACK_API}/task/?limit=5&cursor=${taskId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const taskOperations = async (data, id, token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ tasks: data }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/tasks/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const multipleDeleteTask = async (taskIds, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(taskIds),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/task`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};
// contacts api start here
export const getContacts = async (token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const addContact = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteContact = async (contactId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/${contactId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updateContact = async (data, id, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/user-contacts/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// comments api

export const addComment = async (comment, jobId, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      referenceId: jobId,
      type: 'JOB',
      text: comment,
    }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getComments = async (cursorId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (cursorId === 0) URL = `${env.JOB_TRACK_API}/comment/?limit=20`;
  else URL = `${env.JOB_TRACK_API}/comment/?limit=20&cursor=${cursorId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateComment = async (comment, commentId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ text: comment }),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment/${commentId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteComment = async (commentId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/comment/${commentId}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

// dashboard api

export const getDashboardTimeline = async (cursorId, token) => {
  const fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  let URL = '';
  if (cursorId === 0) URL = `${env.JOB_TRACK_API}/timeline/?limit=20`;
  else URL = `${env.JOB_TRACK_API}/timeline/?limit=20&cursor=${cursorId}`;
  const response = await fetch(URL, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// jobtrack active api

export const activeJobTrack = async (token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/podio/activate-job-tracking`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

// Calendar Api

export const getTaskByRange = async (fromDate, toDate, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/calendar?fromDate=${fromDate}&toDate=${toDate}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.json();
    throw new Error(text);
  }
  return response.json();
};

// Meeting Api

export const createMeeting = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getMeetings = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getMeetingById = async (meetingId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateMeeting = async (data, meetingId, token) => {
  const fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteMeeting = async (meetingId, token) => {
  const fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/meeting/:${meetingId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

// Reminder api

export const createReminder = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/reminder`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const searchAutoComplete = async (title, type, languageCode, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return [];
};

export const cityDefaultSuggestion = async (lat, lon, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  // return [];
  const response = await fetch(`${env.JOB_TRACK_API}/job/default-suggestion?lat=${lat}&lon=${lon}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getBoardList = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/list`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getActiveBoard = async (boardId, token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/board/${boardId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const userActivity = async (token) => {
  const fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.BASE_API}/user/track-activity`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const getOnecenterTitleList = async (token) => {
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/title-list`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updateJob = async (data, token) => {
  const fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  const response = await fetch(`${env.JOB_TRACK_API}/job/update`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
// feeds api start here
export const getFeeds = async (token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getFeedsById = async (id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const saveFeed = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const deleteFeed = async (id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.text();
};

export const updatFeedApi = async (data, id, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${id}`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const resetNewJobCount = async (feedId, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed/${feedId}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const getFeedList = async (country, token) => {
  const { agent_client_id } = nookies.get({});
  const fetchOptions = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job-feed?regionCode=${country}`, fetchOptions);
  if (response.status != 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const savedJobsOnPodio = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  let fetchOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/podio/job`, fetchOptions);
  if (response.status !== 201) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const listPodioSavedJobs = async (token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/agent-user/list-job`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const updatePodioJob = async (data, token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/podio/job-status`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};

export const defaultCandidateSuggesiton = async (token) => {
  const { agent_client_id } = nookies.get({});
  if (!agent_client_id) return { jobs: [] };
  let fetchOptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  if (agent_client_id) {
    fetchOptions.headers['x-user-id'] = agent_client_id;
  }
  const response = await fetch(`${env.JOB_TRACK_API}/job/candidate-search`, fetchOptions);
  if (response.status !== 200) {
    const text = await response.text();
    throw new Error(text);
  }
  return response.json();
};
