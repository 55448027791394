import ct from 'countries-and-timezones';
import moment from 'moment';

import BoardIcon from '/imports/job-tracking/ui/assets/LayoutBoardIcon';
import CalendarIcon from '/imports/job-tracking/ui/assets/LayoutCalendarIcon';
import LayoutSearchIcon from '/imports/job-tracking/ui/assets/LayoutSearchIcon';
import TemplateIcon from '/imports/job-tracking/ui/assets/LayoutTemplateIcon';

export const StepProgressList = [
  'Wishlist',
  'Applying',
  'Applied',
  'Interview',
  'Offer',
  'Offer Accepted',
  'Offer Rejected',
];
export const LinkConfig = [
  {
    as: '/app/dashboard',
    href: '/app?template=dashboard',
    active: 'dashboard',
    title: 'My templates',
    logo: () => <TemplateIcon />,
    slug: 'jobtrack_sidebar_mytemplates_text',
  },
  {
    as: '/app/search',
    href: '/app?template=search',
    active: 'search',
    title: 'Searches',
    logo: () => <LayoutSearchIcon />,
    isFeature: true,
    slug: 'jobtrack_sidebar_searches_text',
  },
  // {
  //   href: '/app/calendar',
  //   as: '/app/calendar',
  //   active: 'calendar',
  //   title: 'Calendar',
  //   logo: () => <CalendarIcon />,
  //   isFeature: true,
  // },
  {
    as: '/app/tracking',
    href: '/app?template=tracking',
    active: 'tracking',
    title: 'My boards',
    logo: () => <BoardIcon />,
    isFeature: true,
    slug: 'jobtrack_sidebar_myboards_text',
  },
];

export const CHIP_LIST = [
  { id: 1, background: 'var(--chip-purple)' },
  { id: 2, background: 'var(--chip-blue)' },
  { id: 3, background: 'var(--chip-yellow)' },
  { id: 4, background: 'var(--chip-gray)' },
  { id: 5, background: 'var(--chip-green)' },
];

export const TAGS_LIST = [
  { text: 'Catering', code: 0 },
  { text: 'Law', code: 1 },
  { text: 'Factory', code: 2 },
  { text: 'Automotive', code: 3 },
  { text: 'Retail', code: 4 },
  { text: 'HR', code: 0 },
  { text: 'Marketing', code: 1 },
  { text: 'Technician', code: 2 },
  { text: 'Sales', code: 3 },
  { text: 'Administration', code: 4 },
  { text: 'Real Estate', code: 0 },
  { text: 'Business', code: 1 },
  { text: 'Other', code: 2 },
  { text: 'IT', code: 3 },
  { text: 'Reception', code: 4 },
  { text: 'Insurance', code: 0 },
  { text: 'Engineering', code: 1 },
  { text: 'Management', code: 2 },
  { text: 'Logistics', code: 3 },
  { text: 'Finance', code: 4 },
  { text: 'Accountancy', code: 0 },
  { text: 'Hospitality', code: 1 },
  { text: 'Customer Service', code: 2 },
  { text: 'Assistant/Secretarial', code: 3 },
  { text: 'Health', code: 4 },
  { text: 'Banking', code: 0 },
  { text: 'Tourism/Leisure', code: 1 },
  { text: 'Construction', code: 2 },
  { text: 'Security/Safety', code: 3 },
  { text: 'Media/Digital/Creative', code: 4 },
  { text: 'Education', code: 0 },
  { text: 'Journalism', code: 1 },
  { text: 'Others', code: 2 },
  { text: 'Driver', code: 3 },
  { text: 'Aviation', code: 4 },
];

export const KEYWORD = [
  { name: 'Health Care', id: 1 },
  { name: 'Software', id: 2 },
  { name: 'Financial Services', id: 3 },
  { name: 'Information Tecnology', id: 4 },
  { name: 'Cyber Security', id: 5 },
  { name: 'Lifestyle', id: 6 },
  { name: 'Professional Services', id: 7 },
];

export const stringToNull = (myObj) => {
  Object.keys(myObj).forEach((k) => {
    myObj[k] = myObj[k] === '' ? null : myObj[k];
  });
  return myObj;
};

export const JobTrackingSteps = ['Wishlist', 'Applied', 'Interview', 'Offer'];

export const SUGGSTION_TAGS = [
  {
    name: 'Date Post',
    value: [
      { value: 'Last 24 hours', slug: 'jobtrack_date_filter_options_1' },
      { value: 'Last 3 days', slug: 'jobtrack_date_filter_options_2' },
      { value: 'Last 7 days', slug: 'jobtrack_date_filter_options_3' },
      { value: 'Last 14 days', slug: 'jobtrack_date_filter_options_4' },
    ],
    id: 'datePost',
    slug: 'jobtrack_datepost_filter_text',
  },
  {
    name: 'Remote',
    value: [
      { value: 'Remote', slug: 'remote_filter_options_1' },
      { value: 'On Site', slug: 'remote_filter_options_2' },
      { value: 'All Jobs', slug: 'remote_filter_options_3' },
    ],
    id: 'remote',
    slug: 'jobtrack_remote_filter_text',
  },
  {
    name: 'Education level',
    value: [
      { value: "Bachelor's degree", slug: 'jobtrack_education_filter_options_1' },
      { value: '12th Pass', slug: 'jobtrack_education_filter_options_2' },
      { value: "Master's degree", slug: 'jobtrack_education_filter_options_3' },
      { value: 'Diploma', slug: 'jobtrack_education_filter_options_4' },
      { value: '10th Pass', slug: 'jobtrack_education_filter_options_5' },
      { value: 'Doctoral degree', slug: 'jobtrack_education_filter_options_6' },
    ],
    id: 'educationLevel',
    slug: 'jobtrack_education_filter_text',
  },
  {
    name: 'Salary Estimate',
    value: ['All Jobs', '$ 12,500+/month', '$ 15,000+/month', '$ 17,500+/month', '$ 20,000+/month', '$ 25,000+/month'],
    id: 'salary',
    slug: 'jobtrack_salary_filter_text',
  },
  {
    name: 'Job Type',
    value: ['All Jobs', 'Full-time', 'Fresher', 'Part-time', 'Internship', 'Contract', 'Temporary'],
    id: 'jobType',
    slug: 'jobtrack_jobtype_filter_text',
  },
  {
    name: 'Skills',
    value: [
      { value: 'Computer skills', slug: 'jobtrack_salary_filter_options_1' },
      { value: 'Time management', slug: 'jobtrack_salary_filter_options_2' },
      { value: 'Customer Service', slug: 'jobtrack_salary_filter_options_3' },
      { value: 'Data entry', slug: 'jobtrack_salary_filter_options_4' },
      { value: 'Organizational Skills', slug: 'jobtrack_salary_filter_options_5' },
      { value: 'Typing', slug: 'jobtrack_salary_filter_options_6' },
      { value: 'Computer Literacy', slug: 'jobtrack_salary_filter_options_7' },
      { value: 'Word Processing', slug: 'jobtrack_salary_filter_options_8' },
      { value: 'Phone Etiquette', slug: 'jobtrack_salary_filter_options_9' },
    ],
    id: 'skills',
    slug: 'jobtrack_skills_filter_text',
  },
  { name: 'Location', value: ['All Jobs', 'Local Jobs'], id: 'location', slug: 'jobtrack_location_filter_text' },
  { name: 'Company', value: ['All Jobs'], id: 'company', slug: 'jobtrack_company_filter_text' },
  {
    name: 'Jobs Provider',
    id: 'partner',
    value: [
      { value: 'Adzuna', slug: 'jobtrack_jobprovider_filter_options_1' },
      { value: 'Jobadx', slug: 'jobtrack_jobprovider_filter_options_2' },
      { value: 'Jobg8', slug: 'jobtrack_jobprovider_filter_options_3' },
      { value: 'Google', slug: 'jobtrack_jobprovider_filter_options_4' },
    ],
    slug: 'jobtrack_jobsprovider_filter_text',
  },
  {
    name: 'Contract Type',
    value: ['CDI', 'CDD', 'Apprentissage', 'Stage', 'Saisonnier', 'Intérim', 'Contrat Pro'],
    id: 'contractType',
    slug: 'jobtrack_contractType_filter',
  },
  { name: 'Check Needed', value: [true, false], id: 'isManualCheck' },
];

export const AGENT_SUGGESTION_TAGS = [
  {
    name: 'Date Post',
    value: [
      { value: 'today', name: 'Today', slug: 'jobtrack_deadline_today_text' },
      { value: '3days', name: 'Last 3 days', slug: 'jobtrack_date_filter_options_2' },
      { value: 'week', name: 'Last week', slug: 'weeks' },
      { value: 'month', name: 'Last month', slug: 'month' },
    ],
    id: 'datePost',
    slug: 'jobtrack_datepost_filter_text',
  },
  {
    name: 'Remote',
    value: [
      { value: 'Remote', slug: 'remote_filter_options_1' },
      { value: 'On Site', slug: 'remote_filter_options_2' },
    ],
    id: 'remote',
    slug: 'jobtrack_remote_filter_text',
  },
  {
    name: 'Job Type',
    value: [
      { value: 'contractor', name: 'Contractor', slug: 'contactor' },
      { value: 'fulltime', name: 'Full Time', slug: 'full_time' },
      { value: 'parttime', name: 'Part Time', slug: 'part_time' },
      { value: 'intern', name: 'Intern', slug: 'intern' },
    ],
    id: 'jobType',
    slug: 'jobtrack_jobtype_filter_text',
  },
  {
    name: 'Job Provider',
    value: [
      { value: 'linkedin', name: 'linkedin' },
      { value: 'indeed', name: 'indeed' },
      { value: 'glassdoor', name: 'glassdoor' },
      { value: 'naukri', name: 'naukri' },
      { value: 'jooble', name: 'jooble' },
    ],
    id: 'allowedJobProviders',
    checkBox: true,
    slug: 'jobtrack_provider',
  },
  {
    name: 'Languages',
    value: [
      { value: 'French', name: 'fr', slug: 'language.french' },
      { value: 'English', name: 'en', slug: 'language.english' },
      { value: 'Spanish', name: 'es', slug: 'language.spanish' },
    ],
    id: 'language',
    slug: 'languages',
  },
];

export const DEFAULT_SEARCH_SUGGESTION = (t) => {
  return [
    t('jobtrack_default_options_1'),
    t('jobtrack_default_options_2'),
    t('jobtrack_default_options_3'),
    t('jobtrack_default_options_4'),
    t('jobtrack_default_options_5'),
    t('jobtrack_default_options_6'),
    t('jobtrack_default_options_7'),
    t('jobtrack_default_options_8'),
    t('jobtrack_default_options_9'),
    t('jobtrack_default_options_10'),
  ];
};

export const SALARY_TAG_SUFFIX = () => {
  const timeZone = moment.tz.guess(true);
  const tz = ct.getTimezone(timeZone);
  if (tz.country === 'FR') {
    return '€ et +/mois';
  }
  return '$ and +/month';
};

export const COUNTRY_LIST = [
  { name: 'Afghanistan', countryCode: 'AF' },
  { name: 'Åland Islands', countryCode: 'AX' },
  { name: 'Albania', countryCode: 'AL' },
  { name: 'Algeria', countryCode: 'DZ' },
  { name: 'American Samoa', countryCode: 'AS' },
  { name: 'Andorra', countryCode: 'AD' },
  { name: 'Angola', countryCode: 'AO' },
  { name: 'Anguilla', countryCode: 'AI' },
  { name: 'Antarctica', countryCode: 'AQ' },
  { name: 'Antigua and Barbuda', countryCode: 'AG' },
  { name: 'Argentina', countryCode: 'AR' },
  { name: 'Armenia', countryCode: 'AM' },
  { name: 'Aruba', countryCode: 'AW' },
  { name: 'Australia', countryCode: 'AU' },
  { name: 'Austria', countryCode: 'AT' },
  { name: 'Azerbaijan', countryCode: 'AZ' },
  { name: 'Bahamas', countryCode: 'BS' },
  { name: 'Bahrain', countryCode: 'BH' },
  { name: 'Bangladesh', countryCode: 'BD' },
  { name: 'Barbados', countryCode: 'BB' },
  { name: 'Belarus', countryCode: 'BY' },
  { name: 'Belgium', countryCode: 'BE' },
  { name: 'Belize', countryCode: 'BZ' },
  { name: 'Benin', countryCode: 'BJ' },
  { name: 'Bermuda', countryCode: 'BM' },
  { name: 'Bhutan', countryCode: 'BT' },
  { name: 'Bolivia (Plurinational State of)', countryCode: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', countryCode: 'BQ' },
  { name: 'Bosnia and Herzegovina', countryCode: 'BA' },
  { name: 'Botswana', countryCode: 'BW' },
  { name: 'Bouvet Island', countryCode: 'BV' },
  { name: 'Brazil', countryCode: 'BR' },
  { name: 'British Indian Ocean Territory', countryCode: 'IO' },
  { name: 'Brunei Darussalam', countryCode: 'BN' },
  { name: 'Bulgaria', countryCode: 'BG' },
  { name: 'Burkina Faso', countryCode: 'BF' },
  { name: 'Burundi', countryCode: 'BI' },
  { name: 'Cabo Verde', countryCode: 'CV' },
  { name: 'Cambodia', countryCode: 'KH' },
  { name: 'Cameroon', countryCode: 'CM' },
  { name: 'Canada', countryCode: 'CA' },
  { name: 'Cayman Islands', countryCode: 'KY' },
  { name: 'Central African Republic', countryCode: 'CF' },
  { name: 'Chad', countryCode: 'TD' },
  { name: 'Chile', countryCode: 'CL' },
  { name: 'China', countryCode: 'CN' },
  { name: 'Christmas Island', countryCode: 'CX' },
  { name: 'Cocos (Keeling) Islands', countryCode: 'CC' },
  { name: 'Colombia', countryCode: 'CO' },
  { name: 'Comoros', countryCode: 'KM' },
  { name: 'Congo', countryCode: 'CG' },
  { name: 'Congo, Democratic Republic of the', countryCode: 'CD' },
  { name: 'Cook Islands', countryCode: 'CK' },
  { name: 'Costa Rica', countryCode: 'CR' },
  { name: "Côte d'Ivoire", countryCode: 'CI' },
  { name: 'Croatia', countryCode: 'HR' },
  { name: 'Cuba', countryCode: 'CU' },
  { name: 'Curaçao', countryCode: 'CW' },
  { name: 'Cyprus', countryCode: 'CY' },
  { name: 'Czechia', countryCode: 'CZ' },
  { name: 'Denmark', countryCode: 'DK' },
  { name: 'Djibouti', countryCode: 'DJ' },
  { name: 'Dominica', countryCode: 'DM' },
  { name: 'Dominican Republic', countryCode: 'DO' },
  { name: 'Ecuador', countryCode: 'EC' },
  { name: 'Egypt', countryCode: 'EG' },
  { name: 'El Salvador', countryCode: 'SV' },
  { name: 'Equatorial Guinea', countryCode: 'GQ' },
  { name: 'Eritrea', countryCode: 'ER' },
  { name: 'Estonia', countryCode: 'EE' },
  { name: 'Eswatini', countryCode: 'SZ' },
  { name: 'Ethiopia', countryCode: 'ET' },
  { name: 'Falkland Islands (Malvinas)', countryCode: 'FK' },
  { name: 'Faroe Islands', countryCode: 'FO' },
  { name: 'Fiji', countryCode: 'FJ' },
  { name: 'Finland', countryCode: 'FI' },
  { name: 'France', countryCode: 'FR' },
  { name: 'French Guiana', countryCode: 'GF' },
  { name: 'French Polynesia', countryCode: 'PF' },
  { name: 'French Southern Territories', countryCode: 'TF' },
  { name: 'Gabon', countryCode: 'GA' },
  { name: 'Gambia', countryCode: 'GM' },
  { name: 'Georgia', countryCode: 'GE' },
  { name: 'Germany', countryCode: 'DE' },
  { name: 'Ghana', countryCode: 'GH' },
  { name: 'Gibraltar', countryCode: 'GI' },
  { name: 'Greece', countryCode: 'GR' },
  { name: 'Greenland', countryCode: 'GL' },
  { name: 'Grenada', countryCode: 'GD' },
  { name: 'Guadeloupe', countryCode: 'GP' },
  { name: 'Guam', countryCode: 'GU' },
  { name: 'Guatemala', countryCode: 'GT' },
  { name: 'Guernsey', countryCode: 'GG' },
  { name: 'Guinea', countryCode: 'GN' },
  { name: 'Guinea-Bissau', countryCode: 'GW' },
  { name: 'Guyana', countryCode: 'GY' },
  { name: 'Haiti', countryCode: 'HT' },
  { name: 'Heard Island and McDonald Islands', countryCode: 'HM' },
  { name: 'Holy See', countryCode: 'VA' },
  { name: 'Honduras', countryCode: 'HN' },
  { name: 'Hong Kong', countryCode: 'HK' },
  { name: 'Hungary', countryCode: 'HU' },
  { name: 'Iceland', countryCode: 'IS' },
  { name: 'India', countryCode: 'IN' },
  { name: 'Indonesia', countryCode: 'ID' },
  { name: 'Iran (Islamic Republic of)', countryCode: 'IR' },
  { name: 'Iraq', countryCode: 'IQ' },
  { name: 'Ireland', countryCode: 'IE' },
  { name: 'Isle of Man', countryCode: 'IM' },
  { name: 'Israel', countryCode: 'IL' },
  { name: 'Italy', countryCode: 'IT' },
  { name: 'Jamaica', countryCode: 'JM' },
  { name: 'Japan', countryCode: 'JP' },
  { name: 'Jersey', countryCode: 'JE' },
  { name: 'Jordan', countryCode: 'JO' },
  { name: 'Kazakhstan', countryCode: 'KZ' },
  { name: 'Kenya', countryCode: 'KE' },
  { name: 'Kiribati', countryCode: 'KI' },
  { name: "Korea (Democratic People's Republic of)", countryCode: 'KP' },
  { name: 'Korea, Republic of', countryCode: 'KR' },
  { name: 'Kuwait', countryCode: 'KW' },
  { name: 'Kyrgyzstan', countryCode: 'KG' },
  { name: "Lao People's Democratic Republic", countryCode: 'LA' },
  { name: 'Latvia', countryCode: 'LV' },
  { name: 'Lebanon', countryCode: 'LB' },
  { name: 'Lesotho', countryCode: 'LS' },
  { name: 'Liberia', countryCode: 'LR' },
  { name: 'Libya', countryCode: 'LY' },
  { name: 'Liechtenstein', countryCode: 'LI' },
  { name: 'Lithuania', countryCode: 'LT' },
  { name: 'Luxembourg', countryCode: 'LU' },
  { name: 'Macao', countryCode: 'MO' },
  { name: 'Madagascar', countryCode: 'MG' },
  { name: 'Malawi', countryCode: 'MW' },
  { name: 'Malaysia', countryCode: 'MY' },
  { name: 'Maldives', countryCode: 'MV' },
  { name: 'Mali', countryCode: 'ML' },
  { name: 'Malta', countryCode: 'MT' },
  { name: 'Marshall Islands', countryCode: 'MH' },
  { name: 'Martinique', countryCode: 'MQ' },
  { name: 'Mauritania', countryCode: 'MR' },
  { name: 'Mauritius', countryCode: 'MU' },
  { name: 'Mayotte', countryCode: 'YT' },
  { name: 'Mexico', countryCode: 'MX' },
  { name: 'Micronesia (Federated States of)', countryCode: 'FM' },
  { name: 'Moldova, Republic of', countryCode: 'MD' },
  { name: 'Monaco', countryCode: 'MC' },
  { name: 'Mongolia', countryCode: 'MN' },
  { name: 'Montenegro', countryCode: 'ME' },
  { name: 'Montserrat', countryCode: 'MS' },
  { name: 'Morocco', countryCode: 'MA' },
  { name: 'Mozambique', countryCode: 'MZ' },
  { name: 'Myanmar', countryCode: 'MM' },
  { name: 'Namibia', countryCode: 'NA' },
  { name: 'Nauru', countryCode: 'NR' },
  { name: 'Nepal', countryCode: 'NP' },
  { name: 'Netherlands', countryCode: 'NL' },
  { name: 'New Caledonia', countryCode: 'NC' },
  { name: 'New Zealand', countryCode: 'NZ' },
  { name: 'Nicaragua', countryCode: 'NI' },
  { name: 'Niger', countryCode: 'NE' },
  { name: 'Nigeria', countryCode: 'NG' },
  { name: 'Niue', countryCode: 'NU' },
  { name: 'Norfolk Island', countryCode: 'NF' },
  { name: 'North Macedonia', countryCode: 'MK' },
  { name: 'Northern Mariana Islands', countryCode: 'MP' },
  { name: 'Norway', countryCode: 'NO' },
  { name: 'Oman', countryCode: 'OM' },
  { name: 'Pakistan', countryCode: 'PK' },
  { name: 'Palau', countryCode: 'PW' },
  { name: 'Palestine, State of', countryCode: 'PS' },
  { name: 'Panama', countryCode: 'PA' },
  { name: 'Papua New Guinea', countryCode: 'PG' },
  { name: 'Paraguay', countryCode: 'PY' },
  { name: 'Peru', countryCode: 'PE' },
  { name: 'Philippines', countryCode: 'PH' },
  { name: 'Pitcairn', countryCode: 'PN' },
  { name: 'Poland', countryCode: 'PL' },
  { name: 'Portugal', countryCode: 'PT' },
  { name: 'Puerto Rico', countryCode: 'PR' },
  { name: 'Qatar', countryCode: 'QA' },
  { name: 'Réunion', countryCode: 'RE' },
  { name: 'Romania', countryCode: 'RO' },
  { name: 'Russian Federation', countryCode: 'RU' },
  { name: 'Rwanda', countryCode: 'RW' },
  { name: 'Saint Barthélemy', countryCode: 'BL' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', countryCode: 'SH' },
  { name: 'Saint Kitts and Nevis', countryCode: 'KN' },
  { name: 'Saint Lucia', countryCode: 'LC' },
  { name: 'Saint Martin (French part)', countryCode: 'MF' },
  { name: 'Saint Pierre and Miquelon', countryCode: 'PM' },
  { name: 'Saint Vincent and the Grenadines', countryCode: 'VC' },
  { name: 'Samoa', countryCode: 'WS' },
  { name: 'San Marino', countryCode: 'SM' },
  { name: 'Sao Tome and Principe', countryCode: 'ST' },
  { name: 'Saudi Arabia', countryCode: 'SA' },
  { name: 'Senegal', countryCode: 'SN' },
  { name: 'Serbia', countryCode: 'RS' },
  { name: 'Seychelles', countryCode: 'SC' },
  { name: 'Sierra Leone', countryCode: 'SL' },
  { name: 'Singapore', countryCode: 'SG' },
  { name: 'Sint Maarten (Dutch part)', countryCode: 'SX' },
  { name: 'Slovakia', countryCode: 'SK' },
  { name: 'Slovenia', countryCode: 'SI' },
  { name: 'Solomon Islands', countryCode: 'SB' },
  { name: 'Somalia', countryCode: 'SO' },
  { name: 'South Africa', countryCode: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', countryCode: 'GS' },
  { name: 'South Sudan', countryCode: 'SS' },
  { name: 'Spain', countryCode: 'ES' },
  { name: 'Sri Lanka', countryCode: 'LK' },
  { name: 'Sudan', countryCode: 'SD' },
  { name: 'Suriname', countryCode: 'SR' },
  { name: 'Svalbard and Jan Mayen', countryCode: 'SJ' },
  { name: 'Sweden', countryCode: 'SE' },
  { name: 'Switzerland', countryCode: 'CH' },
  { name: 'Syrian Arab Republic', countryCode: 'SY' },
  { name: 'Taiwan, Province of China', countryCode: 'TW' },
  { name: 'Tajikistan', countryCode: 'TJ' },
  { name: 'Tanzania, United Republic of', countryCode: 'TZ' },
  { name: 'Thailand', countryCode: 'TH' },
  { name: 'Timor-Leste', countryCode: 'TL' },
  { name: 'Togo', countryCode: 'TG' },
  { name: 'Tokelau', countryCode: 'TK' },
  { name: 'Tonga', countryCode: 'TO' },
  { name: 'Trinidad and Tobago', countryCode: 'TT' },
  { name: 'Tunisia', countryCode: 'TN' },
  { name: 'Turkey', countryCode: 'TR' },
  { name: 'Turkmenistan', countryCode: 'TM' },
  { name: 'Turks and Caicos Islands', countryCode: 'TC' },
  { name: 'Tuvalu', countryCode: 'TV' },
  { name: 'Uganda', countryCode: 'UG' },
  { name: 'Ukraine', countryCode: 'UA' },
  { name: 'United Arab Emirates', countryCode: 'AE' },
  { name: 'United Kingdom of Great Britain and Northern Ireland', countryCode: 'GB' },
  { name: 'United States of America', countryCode: 'US' },
  { name: 'United States Minor Outlying Islands', countryCode: 'UM' },
  { name: 'Uruguay', countryCode: 'UY' },
  { name: 'Uzbekistan', countryCode: 'UZ' },
  { name: 'Vanuatu', countryCode: 'VU' },
  { name: 'Venezuela (Bolivarian Republic of)', countryCode: 'VE' },
  { name: 'Viet Nam', countryCode: 'VN' },
  { name: 'Virgin Islands (British)', countryCode: 'VG' },
  { name: 'Virgin Islands (U.S.)', countryCode: 'VI' },
  { name: 'Wallis and Futuna', countryCode: 'WF' },
  { name: 'Western Sahara', countryCode: 'EH' },
  { name: 'Yemen', countryCode: 'YE' },
  { name: 'Zambia', countryCode: 'ZM' },
  { name: 'Zimbabwe', countryCode: 'ZW' },
];

export const REPORT_LIST = [
  { id: 'It is offensive, discriminatory', slug: 'jobtrack_report_option_discriminatory_text' },
  { id: 'It seems like a fake job', slug: 'jobtrack_report_option_fake_text' },
  { id: 'It is inaccurate', slug: 'jobtrack_report_option_inaccurate_text' },
  { id: 'Link is broken', slug: 'jobtrack_report_option_broken_link_text' },
  { id: 'Other', slug: 'jobtrack_report_option_other_text' },
];

export const ONLY_STAGING_FILTERS = ['educationLevel', 'skills', 'partner', 'isManualCheck'];

export const HIDE_IN_FRENCH = [''];

export const valueMapping = {
  month: 'Last 14 days',
  week: 'Last 7 days',
  today: 'Last 24 hours',
  '3days': 'Last 3 days',
};
